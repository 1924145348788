<form [formGroup]="fG">
  <ng-container *ngIf="style === 'block' || style === 'inline'">
    <div class="e-float-input {{ fG.controls[fCN].invalid && fG.controls[fCN].dirty ? 'e-error' : ''}}">
        <textarea #textAreaElement [formControlName]="fCN" (keyup.enter)="onReturnKeyPress()" class="e-input" [attr.aria-placeholder]="placeholder" [maxlength]="maxChars" [rows]="rows" [cols]="cols"></textarea>
        <span class="e-float-line"></span>
        <label [ngClass]="{'e-label-top': fG.controls[fCN].value !== '' && style === 'inline', 'e-label-bottom':  fG.controls[fCN].value == '' && style === 'inline'}" class="e-float-text">{{ placeholder }}</label>
    </div>
  </ng-container>

  <ng-container *ngIf="style === 'headless'">
    <div class="e-input-group">
      <textarea #textAreaElement [formControlName]="fCN" (keyup.enter)="onReturnKeyPress()" class="e-input {{ fG.controls[fCN].invalid && fG.controls[fCN].dirty ? 'e-error' : ''}}" [placeholder]="placeholder" [attr.aria-placeholder]="placeholder" [maxlength]="maxChars" [rows]="rows" [cols]="cols"></textarea>
    </div>
  </ng-container>

  <div class="error-label" role="alert" *ngIf="errorText && fG.controls[fCN].invalid && fG.controls[fCN].dirty">
    {{ errorText }}
  </div>
</form>
