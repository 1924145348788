import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";
import {RichTextEditor, Toolbar, Link, Image, HtmlEditor, QuickToolbar} from '@syncfusion/ej2-richtexteditor';

@Component({
  selector: 'app-form-textarea',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './form-textarea.component.html',
  styleUrl: './form-textarea.component.scss'
})
export class FormTextareaComponent implements OnInit, AfterViewInit {
  @ViewChild('textAreaElement') textAreaElement: any;

  @Input() placeholder: string = '';
  @Input() fCN: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() errorText: string = '';
  @Input() style: 'inline' | 'block' | 'headless' = 'headless';
  @Input() disabled: boolean = false;
  @Input() rows: number = 3;
  @Input() cols: number = 35;
  @Input() maxChars: number = 0;
  @Input() richText: boolean = false;
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() returnKeyPress: EventEmitter<void> = new EventEmitter<void>();

  public constructor() {
  }

  public ngOnInit(): void {
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }

    if (this.maxChars) {
      this.fG.controls[this.fCN].valueChanges.subscribe((value: string) => {
        if (value.length > this.maxChars) {
          this.fG.controls[this.fCN].setValue(value.substring(0, this.maxChars));
        }
      });
    }
  }

  public onReturnKeyPress(): void {
    this.returnKeyPress.emit();
  }

  public ngAfterViewInit(): void {
    if (this.richText) {
      RichTextEditor.Inject(Toolbar, Link, Image, HtmlEditor, QuickToolbar);
      let editor: RichTextEditor = new RichTextEditor({
        toolbarSettings: {
          items: ['Bold', 'Italic', 'Underline', '|', 'EmojiPicker', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', '|', 'ClearFormat', '|', 'Undo', 'Redo']
        }});
      editor.appendTo(this.textAreaElement.nativeElement);
    }
  }
}
